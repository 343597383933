import React, { useContext, useEffect, useState , lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext, UserContextProvider } from './Context/UserContext';
import logo from './logo.svg';
import './App.css';
import Unauthorized from './Unauthorized';
const Boards = lazy(() => import('./Dashboard/Components/Boards/Index'));
const Home = lazy(() => import('./Pages/Home/index'));
const About = lazy(() => import('./Pages/About/Index'));
const RESTAURANT = lazy(() => import('./Pages/Restaurant/index'));
const Contact = lazy(() => import('./Pages/Contact/index'));
const Login = lazy(() => import('./Pages/Login/index'));
const Offers = lazy(() => import('./Pages/Offers/index'));
const Packages = lazy(() => import('./Pages/Prices/index'));
const RebaliRiads = lazy(() => import('./Pages/Accomodations/Rebali Riads/index'));
const Feru = lazy(() => import('./Pages/Accomodations/Feru/index'));
const AbdlaApartment = lazy(() => import('./Pages/Accomodations/Apartments Abdla/index'));
const Windykaouki = lazy(() => import('./Pages/Accomodations/Windy Kaouki/index'));
const Marabout = lazy(() => import('./Pages/Accomodations/Marabout/index'));
const Accodetail = lazy(() => import('./Pages/Accomodations/Accomodation-detail/Main'));
const Pkgdetail = lazy(() => import('./Pages/Packages/Packages-detail/Main'));
const Campdetail = lazy(() => import('./Pages/Camps/Camps-detail/Main'));
const BudgetPackage = lazy(() => import('./Pages/Packages/Budget Package/index'));
const FamilyPackage = lazy(() => import('./Pages/Packages/Family Package/index'));
const AdventurePackage = lazy(() => import('./Pages/Packages/Adventure Package/index'));
const CouplePackage = lazy(() => import('./Pages/Packages/Couples Package/index'));
const LuxuryPackage = lazy(() => import('./Pages/Packages/Luxury Package/index'));
const SHRCAMP = lazy(() => import('./Pages/Camps/SURF & HORSE RIDING CAMP/index'));
const SYCAMP = lazy(() => import('./Pages/Camps/SURF AND YOGA CAMP/index'));
const CAPCAMP = lazy(() => import('./Pages/Camps/Capoeira/index'));
const WSFSCAMP = lazy(() => import('./Pages/Camps/WINDSURF, SURF, FOIL & SUP CAMP/index'));
const TravelPackage = lazy(() => import('./Pages/Travel-Package/Index'));
const Dashboard = lazy(() => import('./Dashboard/Home/index'));
const UserDashboard = lazy(() => import('./Dashboard/UserDashboard'));
const Entity = lazy(() => import('./Dashboard/Datatable/index'));
const NewEntity = lazy(() => import('./Dashboard/Entity/index'));
const Checkout = lazy(() => import('./Pages/Checkout/Index'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const UpdateIndex = lazy(() => import('./Dashboard/Entity/UpdateIndex'));
const NewAccomodations = lazy(() => import('./Dashboard/Accomodations/index'));
const List = lazy(() => import('./Dashboard/Accomodations/Datatable/index'));
const UpdateAccomodations = lazy(() => import('./Dashboard/Accomodations/Update/index'));
const NewHotel = lazy(() => import('./Dashboard/Hotel/index'));
const HotelList = lazy(() => import('./Dashboard/Hotel/Datatable/index'));
const UpdateHotel = lazy(() => import('./Dashboard/Hotel/Update/index'));
const NewPackage = lazy(() => import('./Dashboard/Packages/Index'));
const Packageslist = lazy(() => import('./Dashboard/Packages/Datatable/Index'));
const UpdatePackage = lazy(() => import('./Dashboard/Packages/Update/Index'));
const Camps = lazy(() => import('./Dashboard/Camps/Datatable/Index'));
const NewCamps = lazy(() => import('./Dashboard/Camps/Index'));
const UpdateCamps = lazy(() => import('./Dashboard/Camps/Update/Index'));
const Allbookings = lazy(() => import('./Dashboard/Bookings/index'));
const CheckoutSuccess = lazy(() => import('./Pages/Checkout/CheckoutSuccess'));
const Packageslists = lazy(() => import('./Dashboard/NewPackages/Datatable/index'));
const NewPackages = lazy(() => import('./Dashboard/NewPackages/index'));
const Campslists = lazy(() => import('./Dashboard/NewCamps/Datatable/index'));
const Newcamps = lazy(() => import('./Dashboard/NewCamps/index'));
const PagesList = lazy(() => import('./Dashboard/Pages/Datatable/index'));
const NewPages = lazy(() => import('./Dashboard/Pages/index'));
const UpdateHome = lazy(() => import('./Dashboard/Pages/Update/index'));
const ContactList = lazy(() => import('./Dashboard/ContactEntries/Datatable/index'));
const NewsletterList = lazy(() => import('./Dashboard/NewsletterEntries/Datatable/index'));
const BlogList = lazy(() => import('./Dashboard/NewBlog/Datatable/index'));
const NewBlog = lazy(() => import('./Dashboard/NewBlog/index'));
const BlogDetail = lazy(() => import('./Pages/Blog-detail/index'));
const UpdateP = lazy(() => import('./Dashboard/NewPackages/Update/index'));
const UpdateC = lazy(() => import('./Dashboard/NewCamps/Update/index'));
const NewCoupon = lazy(() => import('./Dashboard/Coupon/index'));
const Coupon = lazy(() => import('./Dashboard/Coupon/Datatable/index'));
const ManualBooking = lazy(() => import('./Dashboard/ManualBooking'));


function App(props) {
  const auth = localStorage.getItem('user')
  

  return (
      <UserContextProvider>
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/restaurant" element={<RESTAURANT />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/booking" element={<ManualBooking />} />
          <Route exact path="/prices" element={<Packages />} />
          {/* Unauthorized Page */}
          <Route exact path="/unauthorized" element={<Unauthorized />} />
          <Route exact path="/budget-package" element={<BudgetPackage />} />
          <Route exact path="/family-package" element={<FamilyPackage />} />
          <Route exact path="/adventure-package" element={<AdventurePackage />} />
          <Route exact path="/couple-package" element={<CouplePackage />} />
          <Route exact path="/luxury-package" element={<LuxuryPackage />} />
          <Route exact path="/surf-horse-riding-camp" element={<SHRCAMP />} />
          <Route exact path="/surf-and-yoga-camp" element={<SYCAMP />} />
          <Route exact path="/capoeira-camp" element={<CAPCAMP />} />
          <Route exact path="/windsurf-surf-foil-sup-camp" element={<WSFSCAMP />} />
          <Route exact path="/travelpackage" element={<TravelPackage />} />
          <Route exact path="/rebali-riads" element={<RebaliRiads />} />
          <Route exact path="/feru" element={<Feru />} />
          <Route exact path="/abdla-apartments" element={<AbdlaApartment />} />
          <Route exact path="/windy-kaouki" element={<Windykaouki />} />
          <Route exact path="/marabout" element={<Marabout />} />
          <Route exact path="/accomodation-detail/:id" element={<Accodetail />} />
          <Route exact path="/package-detail/:id" element={<Pkgdetail />} />
          <Route exact path="/camp-detail/:id" element={<Campdetail />} />
          <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/paymentConfirm/:id" element={<CheckoutSuccess />} />
          
          {/* Protected Routes */}
        <Route element={<PrivateRoute allowedRoles={['user']} />}>
          <Route exact path="/dashboard" element={<UserDashboard />} />
        </Route>

          <Route element={<PrivateRoute allowedRoles={['admin']} />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/entities" element={<Entity />} />
            <Route exact path="/new-entity" element={<NewEntity />} />
            <Route exact path="/update-entity/:id" element={<UpdateIndex />} />
            <Route exact path="/new-accomodations" element={<NewAccomodations />} />
            <Route exact path="/accomodations" element={<List />} />
            <Route exact path="/update-accomodations/:id" element={<UpdateAccomodations />} />
            <Route exact path="/new-hotel" element={<NewHotel />} />
            <Route exact path="/hotels" element={<HotelList />} />
            <Route exact path="/update-hotel/:id" element={<UpdateHotel />} />
            <Route exact path="/new-package" element={<NewPackage />} />
            <Route exact path="/packages" element={<Packageslist />} />
            <Route exact path="/update-packages/:id" element={<UpdatePackage />} />
            <Route exact path="/update-package/:id" element={<UpdateP />} />
            <Route exact path="/camps" element={<Camps />} />
            <Route exact path="/new-camps" element={<NewCamps />} />
            <Route exact path="/update-camps/:id" element={<UpdateCamps />} />
            <Route exact path="/bookings/" element={<Allbookings />} />
            <Route exact path="/packageslist" element={<Packageslists />} />
            <Route exact path="/newpackages" element={<NewPackages />} />
            <Route exact path="/campslist" element={<Campslists />} />
            <Route exact path="/newcamps" element={<Newcamps />} />
            <Route exact path="/pageslist" element={<PagesList />} />
            <Route exact path="/newpages" element={<NewPages />} />
            <Route exact path="/update-home/:id" element={<UpdateHome />} />
            <Route exact path="/contactlist" element={<ContactList />} />
            <Route exact path="/newsletterlist" element={<NewsletterList />} />
            <Route exact path="/posts" element={<BlogList />} />
            <Route exact path="/new-post" element={<NewBlog />} />
            <Route exact path="/update-p/:id" element={<UpdateP />} />
            <Route exact path="/update-c/:id" element={<UpdateC />} />
            <Route exact path="/new-coupon" element={<NewCoupon />} />
            <Route exact path="/coupons" element={<Coupon />} />
            <Route exact path="/boards" element={<Boards />} />
          </Route>
        </Routes>

        </Suspense>

      <Toaster position="top-right" />
    </BrowserRouter>
      </UserContextProvider>
  );
}
export default App;
